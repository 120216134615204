import { useCryptoData } from '../useCryptoData/useCryptoData';

let axios = require('axios');
let url = process.env.REACT_APP_API;


export const useAuthUser = () => {

  const { encryptPermissionsTree } = useCryptoData();

  const signIn = async (user, password) => {
    return await axios.get(url + "/user/login/", {
      params: {
        user: user,
        password: password
      }
    })
    .then(function (response) {
      sessionStorage.setItem('userToken', response.data.token);
      sessionStorage.setItem('userTokenAtt', response.data.tokenAtt);
      const encryptedData = encryptPermissionsTree(response.data.user);
      sessionStorage.setItem('data', encryptedData);

      return {
              Authenticated : true,
              msg: response.data.message
            } 
    })
    .catch(function (error) {

      if (error.response) {
        return {
          Authenticated : false,
          msg: error.response.data.message
        } 
      }
    })
  };

  const signOut = () => {
    sessionStorage.clear();
  };

  const verifyPassword = async (user, password) => {

    return await axios.get(url + "/user/verifyPassword/", {
      params: {
        user: user,
        password: password
      }
    })
    .then(function (response) {
      return { Authenticated : true, msg: response.data.message} 
    })
    .catch(function (error) {
      if (error.response) {
        return {
          Authenticated : false,
          msg: error.response.data.message
        } 
      }
    })
  };

  return { signIn, signOut, verifyPassword }

}
