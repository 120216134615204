import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment-timezone';

import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');
moment.tz.setDefault('America/Manaus');

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
let url = process.env.REACT_APP_API+ "/requests/createService";
let urlUsers = process.env.REACT_APP_API+ "/user/all";
let urlStatus = process.env.REACT_APP_API+ "/requests/status";
let urlTypeServices = process.env.REACT_APP_API+ "/requests/typeServices";


const rangeTimes = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const RequestAddService = (props) => {
    const { decryptPermissionsTree } = useCryptoData();
    const user = decryptPermissionsTree(sessionStorage.getItem('data'));
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [errors, setErrors] = useState({});
    const { data : listUsers, postData } = useFetchData(urlUsers);
    const { data : TypeServices} = useFetchData(urlTypeServices);
    const { ShowMessage } = useShowMessages();

    const handleChangeErros = (err, state) => {
      setErrors((prevErrors) => ({
          ...prevErrors,
          [err]: state
        }));
    };
    
    const handleChangeUser = (values) => {
        props.setValues((prevValues) => (
          {
              ...prevValues, "user": (!values) ? null : values.id,
        }));  
        props.setValues((prevValues) => (
          {
              ...prevValues, "nameUser": (!values) ? '' : values.name,
        }));   

        handleChangeErros('user', !values.id)
        
      }

    const handleChangeTypeService = (values) => {

      props.setValues((prevValues) => (
        {
            ...prevValues, "type_service": (!values) ? null : values.id,
      }));  
      props.setValues((prevValues) => (
        {
            ...prevValues, "type_service_description": (!values) ? '' : values.description,
      })); 
      
      handleChangeErros('type_service', values.id >= 0 ? false : true)
    }

    const handleChangeValues = (value) => {  
        props.setValues((prevValues) => ({
          ...prevValues, 
          [value.target.name] : value.target.value,
        }));
        handleChangeErros([value.target.name], !value.target.value)
    };

    const handlerChangeStartDate= (value) => {  
      props.setValues((prevValues) => ({
        ...prevValues, 
        'start_date' : value
      }));
    };

    const handleClose = () => {
      props.handleChanged();
      props.setOpen(false);
    }

      const sendValues = async (values) => {
        let request = values;
        
        request.start_date = dayjs(request?.start_date).utc(true);
        
        postData(url, request).then((res) => {
            if(res.success){
              ShowMessage('success', 'Bom trabalho',  'Serviço lançado com sucesso!'); 
              props.handleChanged();
              handleClose();
            } else {
              ShowMessage('error', res.msg);
            }
          });
      }

      const handleClickSend = async () => {

        if(!props.values.user){
          handleChangeErros('user', true);
          return false;
        }
        if(!props.values.type_service && props.values.type_service !== 0){
          handleChangeErros('type_service', true);
          return false;   
        }        if(!props.values.description){
          handleChangeErros('description', true);
          return false;   
        }
        sendValues(props.values);
      }

      const selectTime = (time) => {
        const now = dayjs.tz().format();
        const start_date = moment(now).add(time, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        handlerChangeStartDate(dayjs.tz(start_date));
      }

  return (
    <Dialog open={props.open} >
        <DialogTitle>
            Inclusão de Serviços
        </DialogTitle>
        <DialogContent sx={{width: '500px'}}>                
            <Autocomplete
                      disablePortal
                      fullWidth
                      options={listUsers}
                      sx={{ mt: 2}}
                      getOptionLabel={ (option) => option['name'] || option }    
                      value={props.values?.nameUser}
                      onChange={(event, values) => handleChangeUser(values)}
                      renderInput={(params) => 
                      <TextField
                        name="user"
                        {...params} label="Atendente" 
                        error={errors?.user}
                        helperText={errors?.user && "Informe o atendente"} 
                      />}
            />
            <Autocomplete
                      disablePortal
                      fullWidth
                      options={TypeServices}
                      sx={{ mt: 2}}
                      getOptionLabel={ (option) => option['description'] || option }    
                      value={props.values?.type_service_description}
                      onChange={(event, values) => handleChangeTypeService(values)}
                      renderInput={(params) => 
                      <TextField
                        name="type_service"
                        {...params} label="Tipo de Serviço" 
                        error={errors?.type_service}
                        helperText={errors?.type_service && "Informe o tipo de serviço"} 
                      />}
            />            
            <TextField sx={{ mt: 2}}
                label="Abertura do Atendimento"
                multiline
                rows={4}
                fullWidth                
                name='description'
                defaultValue={props?.values?.description}
                onBlur={handleChangeValues}
                placeholder='Informe a abertura do Atendimento'
                variant="outlined"
                error={errors?.description}
                helperText={errors?.description && "Informe a descrição corretamente"}

            /> 
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
              <MobileDateTimePicker 
                fullWidth
                sx={{width: '100%', mt: 2}}
                label={'Previsão para Atendimento'} 
                ampm={false} 
                openTo="hours" 
                name="start_date"
                value={dayjs.tz(props.values?.start_date)}
                onChange={handlerChangeStartDate}
                format="DD/MM/YYYY HH:mm" 
              />
            </LocalizationProvider>   
            <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
            {rangeTimes.map((item) =>(
              <Chip 
                key={item}
                label={item} 
                variant="outlined" 
                onClick={(e) => {selectTime(item)}} 
              />   
            ))}
            </Box>                
        </DialogContent>
        <DialogActions>
        <Button disabled={disabledButtons} onClick={handleClose} variant="outlined" color='error'>Cancelar</Button>
            <Button 
              disabled={disabledButtons} 
              onClick={() => handleClickSend()} 
              variant="outlined" 
              color="success"       
              >
                Concluir
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default RequestAddService;