import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

//Hooks

import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import { useShowMessages } from '../../../../hooks/useMessages/useMessages';

//Mui 
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Checkbox, Chip, Collapse, Divider, FormControl, FormControlLabel, Grid, IconButton, 
    InputLabel,  MenuItem, Modal, Select, Tooltip, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import CloseIcon from '@mui/icons-material/Close';
import RequestAddService from '../requestAddService/requestAddService';
import { useCryptoData } from '../../../../hooks/useCryptoData/useCryptoData';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SaveIcon from '@mui/icons-material/Save';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import useClientSelection from '../../../../hooks/useClientSelection/useClientSelection';
import { useDebounce } from '../../../../hooks/useDebounce/useDebounce';

let url = process.env.REACT_APP_API+ "/requests";
let urlUsers = process.env.REACT_APP_API+ "/user/all";
const urlFR = process.env.REACT_APP_API+ '/fr';
let urlTypeServices = process.env.REACT_APP_API+ "/requests/typeServices";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Manaus');

export default function RequestAdmin(props) {
  const { decryptPermissionsTree } = useCryptoData();
  const user = decryptPermissionsTree(sessionStorage.getItem('data'));
  const { data : listUsers, editData } = useFetchData(urlUsers);
  const { ShowMessage, msgConfirm } = useShowMessages();
  const [errors, setErrors] = useState({});
  const { data: listRequest} = useFetchData(urlFR, {} )
  const [expandRequests, setExpandRequests] = useState(false);
  const { data : TypeServices} = useFetchData(urlTypeServices);
  const [filter, setFilter] = useState(props?.values?.title || '');
  const disabled = false;
  const [clientPriority, setClientPriority] = useState(false);
  const { debounce } = useDebounce();

  debounce((1*1000), () => {
    setClientPriority(!clientPriority);
  });

  const updateClient = (client) => {

    if(!client) return;

    props.setValues((prevValues) => (
      {
          ...prevValues, "client": client?.id,
    }));  
    props.setValues((prevValues) => (
      {
          ...prevValues, "nome_fantasia": client?.nome_fantasia,
    })); 
  }

  const { selectedClient, openModal, modal, setSelectedClient } = useClientSelection(updateClient);

  // New Service
  const [openAddService, SetOpenAddService] = useState(false);
  const [request, setRequest] = useState({});
  
  const booleanFinalize = (props?.values?.status === 4) && (props.values?.type_service === 30 || user?.requests_finalize === 'Y');

  const handleChangeErros = (err, state) => {
    setErrors((prevErrors) => ({
        ...prevErrors,
        [err]: state
      }));
  };

  const copyTitleRequest = () => {
    let text = `OS: ${props.values?.id_gerente} - ID ${props.values?.id}.${props.values?.sequence} - ${props.values?.nome_fantasia}`
    navigator.clipboard.writeText(text);
    ShowMessage('success',  'Copiado para área de transferência'); 
  }

  const handlerChangeValues = (value) => {

    if(value.target.name == "type_request"){
      handleChangeErros([value.target.name], value.target.value == null ) 
    } else {
      handleChangeErros([value.target.name], !value.target.value)
    }
    

    props.setValues((prevValues) => ({
      ...prevValues,
      [value.target.name]: value.target.value,
    }));

    if(value.target.name == "title"){
      setFilter(value.target.value);
      props.setValues((prevValues) => ({
        ...prevValues,
        'id_reason': null,
      })); 
      handleChangeErros('id_reason', true); 
    }
  }; 

  const handleChangeChecks = (value) => {
    props.setValues((prevValues) => ({
      ...prevValues, 
      [value.target.name] : (value.target?.checked) ? 'Y' : 'N',
    }));
  }

  const handlerClose = () => {
    props.setValues({});
    props.setOpenRequest(false);
    setErrors({});
  };

  const validErros = (values) => {
 
    if(!values.id_reason){
      handleChangeErros('id_reason', true);
      return false;
    } 

    if(!values.title){
      handleChangeErros('id_reason', true);
      return false;
    } 

    if(!values.description){
      handleChangeErros('description', true);
      return false;   
    }
    if(!values.user){
      handleChangeErros('user', true);
      return false;
    }
    if(!values.start_date){
      handleChangeErros('start_date', true);
      return false;
    }
    if(values.type_request == null ){
      handleChangeErros('type_request', true);
      return false;
    }
    if(!values.priority){
      handleChangeErros('priority', true);
      return false;
    }

    if(!values.status && values.status != 0){
      handleChangeErros('status', true);
      return false;
    }
    if(!values.conclusion && values.status > 3){
      handleChangeErros('conclusion', true);
      return false;    
    }

    return true;
  }


  const sendRequest = async (values) => {

    if(!validErros(values)) return;

    values.start_date = dayjs(values?.start_date).utc(true);
    values.conclusion_date = dayjs(values?.conclusion_date).utc(true);

    editData(url, values).then((res) => {
      if(res.success){
        ShowMessage('success', 'Sucesso!', res.msg);
        props.handleChanged();
        handlerClose();
      } else {
        ShowMessage('error', res.msg);
      }
    });
  }
 
  const handlerChangeUser = (values) => {

    props.setValues((prevValues) => (
      {
          ...prevValues, "user": (!values) ? null : values.id,
    }));  
    props.setValues((prevValues) => (
      {
          ...prevValues, "nameUser": (!values) ? '' : values.name,
    }));      

    handleChangeErros('user', !values.id);

  }

  const handlerChangeStartDate= (value) => {  
    props.setValues((prevValues) => ({
      ...prevValues, 
      'start_date' : dayjs(value?.$d)
    }));
  };


  const handlerChangeConcludeDate= (value) => {  
    props.setValues((prevValues) => ({
      ...prevValues, 
      'conclusion_date' : dayjs(value?.$d)
    }));
  };  

  const sendFinalizeRequest = () => {
    let values = props.values;
    values.status = 5;
    values.finish_user = user?.id;
    sendRequest(values);
  }

  const finalizeRequest = () => {
    if(validErros(props.values)){
      msgConfirm('Você tem certeza?', 'Este processo não poderá ser desfeito!', 'Sim, finalizar!', 'Cancelar', sendFinalizeRequest)
    } 
  }

  const handleTitleClick = (value) => {
    
    props.setValues((prevValues) => ({
      ...prevValues, 
      'title' :value?.description
    }));
    props.setValues((prevValues) => ({
      ...prevValues, 
      'id_reason': value?.id
    }));

    setFilter(value?.description); 
    setExpandRequests(false);
    handleChangeErros('id_reason', false); 
    handleChangeErros('title', false);
  }; 

  const AddService = () => {
    let request = { ...props.values };
    request.creator_user = user?.id;
    request.user = (props.userFilter) ? props.userFilter : user?.id;;
    request.sequence = null;
    request.description = '';
    request.status = 2;
    request.title = '';

    setRequest(request);
    SetOpenAddService(true);
  }

  const handleChangeTypeService = (values) => {
    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service": (!values) ? null : values.id,
    }));  
    props.setValues((prevValues) => (
      {
          ...prevValues, "type_service_description": (!values) ? '' : values.description,
    }));        
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid secondary',
    boxShadow: 24,
    borderRadius: '15px',
    p: 3,
    display: 'flex'
  };

  const styleButtons = {
    width: 120,
    m: 1,
    fontSize: 11.5,
    textAlign: 'center',

  }

  const selectClient = () => {
    openModal();
  }

  return (
    <div>
      {modal}
      <RequestAddService values={request} open={openAddService} setOpen={SetOpenAddService} setValues={setRequest} handleChanged={props.handleChanged} />
      <Modal
        open={props.open}
        onClose={handlerClose}
        disableEnforceFocus={true}
      >
        <Box sx={style}>
          <Box>
            <Box sx={{display: 'flex',  width: 500, mb: 2, }} >
              <Typography sx={{width: '80%'}} onDoubleClick={() => copyTitleRequest()}>
                #{props.values?.id}.{props.values?.sequence} - {props.values?.nome_fantasia}  
              </Typography> 
              <Typography
                sx={{
                  flex: 1,
                  marginRight: 0.1,
                  textAlign: 'right',
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              OS: {props.values?.id_gerente}
              </Typography>
            </Box>
            
            <Box  sx={{display: 'flex'}} >
              <Box  sx={{mb: 1, width: 500, overflow: 'auto', maxHeight: '75vh'}} >
                <Box sx={{textAlign: 'center', mb: 1, color: 'gray'}}>
                  {props.values?.status === 5 && <Typography>Este Atendimento foi Arquivado</Typography>}
                </Box>
                <Box sx={{textAlign: 'center', mb: 1, color: 'red', 
                        display: (props.values?.client_priority === 2) ? 'flex' : 'none', 
                        flexDirection: 'row',
                        justifyContent: 'center', minHeight: 38.5,
                        border: '2px solid red'}}>
                  <Typography
                    sx={{
                      fontSize: 23,
                      display: (clientPriority) ? '' : 'none'
                    }}
                  >
                    Cliente Prioridade!
                  </Typography>
                </Box>                
                <Box sx={{'& .MuiTextField-root': {mb: 1.5}, pt: 0.5}}>
                  <Box                 
                    onFocus={() => setExpandRequests(true)}
                    onBlur={() => setExpandRequests(false)}
                  >
                    <TextField
                        id='titleLabel'
                        InputLabelProps={{ shrink: true }}
                        autoFocus={false}
                        name="title"
                        value={props?.values.title}
                        label="Tipo de Atendimento"
                        placeholder='Motivo da solicitação'  
                        type="text"
                        onChange={handlerChangeValues}
                        fullWidth
                        variant="outlined"
                        error={errors?.id_reason}
                        helperText={errors?.id_reason && "Informe o motivo da solicitação"} 
                    />
                    <Collapse in={expandRequests} timeout="auto" unmountOnExit sx={{ textAlign: "left", }}>
                      <Box sx={{float: "left",  '& .MuiChip-root': { m: 1}, marginBottom: 2}}>
                            {listRequest?.filter((i) => i?.description.toLowerCase().includes(filter.toLowerCase())).slice(0, 9).map((item) =>(
                                <Chip 
                                key={item.id}
                                  label={item?.description} 
                                  variant="outlined" 
                                  onClick={(e) => {handleTitleClick(item)}} 
                                />   
                            ))}
                      </Box>
                    </Collapse>  
                  </Box>
                  <TextField
                    label="Descrição do Problema"
                    name='description'
                    multiline
                    fullWidth   
                    variant="outlined"             
                    placeholder='Descreve sua solicitação detalhadamente'                 
                    defaultValue={props?.values?.description}
                    onBlur={handlerChangeValues}
                    error={errors?.description}
                    helperText={errors?.description && "Informe a descrição corretamente"} 
                    InputProps={{readOnly: disabled }}
                  /> 
                  <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
                    <Grid item xs={12} md={7}   >
                      <TextField 
                        id="filled-basic"  
                        label="Falar com:" 
                        variant="outlined" 
                        name='contact'
                        onBlur={handlerChangeValues}
                        defaultValue={props.values?.contact}
                        fullWidth
                        // error={errors?.contact}
                        helperText={errors?.contact && "Informe o nome para contato"} 
                        InputProps={{readOnly: disabled }}
                      />    
                    </Grid>       
                    <Grid item xs={12} md={5}  >
                      <InputMask
                        mask="(99) 99999-9999"
                        disabled={false}
                        maskChar=""
                        defaultValue={props?.values?.phone_number}  
                        onBlur={handlerChangeValues}
                        helperText={errors?.phone_number && "Informe o número para contato"}    
                        InputProps={{readOnly: disabled }}                 
                      >
                        {() =>                     
                        <TextField 
                          id="filled-basic"  
                          label="Número para contato" 
                          variant="outlined"  
                          name='phone_number'
                          fullWidth
                        />
                          }
                      </InputMask>     
                    </Grid> 
                  </Grid>
                  <Divider/>
                  <Grid container spacing={1} columns={ 12 } sx={{float: "center", mt: 0.2}} >
        
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: 15, mb: 1}}>Informações para o Atendimento</Typography> 
                    </Grid>   
                    <Grid item xs={12} md={7} sx={{ mb: 1 }}>
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={listUsers}     
                        getOptionLabel={ (option) => option['name'] || option }    
                        value={props.values?.nameUser}
                        onChange={(event, values) => handlerChangeUser(values)}
                        renderInput={(params) => 
                        <TextField
                          name="user"
                          {...params} label="Atendente" 
                          error={errors?.user}
                          helperText={errors?.user && "Informe o atendente"} 
                          // InputProps={{readOnly: disabled }}
                        />}  
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker 
                          fullWidth
                          sx={{width: '100%'}}
                          label={'Previsão para Atendimento'} 
                          ampm={false} 
                          openTo="hours" 
                          name="start_date"
                          value={dayjs.tz(props.values?.start_date)}
                          onChange={handlerChangeStartDate}
                          error={errors?.start_date}
                          helperText={errors?.start_date && "Informe a data de atendimento"} 
                          disabled={disabled}
                          format="DD/MM/YYYY HH:mm" 
                          />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{mb: 1}}>
                      <FormControl fullWidth>
                        <InputLabel id="tipo-atendimento-label">Tipo</InputLabel>
                        <Select
                            labelId="tipo-atendimento-label"
                            label="Tipo"
                            fullWidth
                            name="type_request"
                            value={props.values?.type_request} 
                            onChange={(e) => handlerChangeValues(e)}
                            error={errors?.type_request}
                            helperText={errors?.type_request && "Informe o tipo de atendimento"} 
                            disabled={user?.requests_edit_client != 'Y'}
                        >
                            <MenuItem value={0}><em>Automação</em></MenuItem>
                            <MenuItem value={1}><em>Ponto</em></MenuItem>
                            <MenuItem value={2}><em>Assistência</em></MenuItem>
                            <MenuItem value={3}><em>Maker</em></MenuItem>
                            <MenuItem value={4}><em>Site</em></MenuItem>                            
                        </Select>
                      </FormControl>  
                    </Grid> 
                    <Grid item xs={12} md={8} sx={{ mb: 1 }}>
                      <Autocomplete
                            disablePortal
                            fullWidth
                            name='type_service_description'
                            options={TypeServices}
                            getOptionLabel={ (option) => option['description'] || option }    
                            value={props.values?.type_service_description}
                            onChange={(event, values) => handleChangeTypeService(values)}
                            renderInput={(params) => 
                            <TextField
                              name="type_service"
                              {...params} label="Tipo de Serviço" 
                            />}
                  /> 
                    </Grid>
                    <Grid item xs={12} md={6} sx={{mb: 1}}>
                      <FormControl fullWidth>
                          <InputLabel id="tipo-prioridade-label">Prioridade</InputLabel>
                          <Select
                              labelId="tipo-prioridade-label"
                              label="Prioridade"
                              fullWidth
                              name="priority"
                              value={props?.values?.priority}   
                              onChange={handlerChangeValues}
                              error={errors?.priority}
                              helperText={errors?.priority && "Informe a prioridade"} 
                              disabled={disabled}
                          >
                              <MenuItem value={1}><em>Normal</em></MenuItem>
                              <MenuItem value={2}><em>Preferencial</em></MenuItem>
                          </Select>
                      </FormControl>   
                    </Grid>
                    <Grid item xs={12} md={6}  sx={{mb: 1}} >
                      <FormControl fullWidth>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          labelId="status-label"
                          label="Status"
                          fullWidth
                          name="status"
                          value={props?.values?.status}
                          onChange={handlerChangeValues}
                          error={errors?.status}
                          helperText={errors?.status && "Informe a prioridade"} 
                          disabled={disabled}
                        >
                          <MenuItem value={1}><em>Nova Solicitação</em></MenuItem>
                          <MenuItem value={2}><em>Aguardando</em></MenuItem>
                          <MenuItem value={0}><em>Check-In em Aberto</em></MenuItem>
                          <MenuItem value={3}><em>Em Atendimento</em></MenuItem>
                          <MenuItem value={4}><em>Concluído</em></MenuItem>
                          <MenuItem value={6}><em>Cancelado</em></MenuItem>
                          <MenuItem value={5}><em>Finalizado</em></MenuItem>
                        </Select>
                      </FormControl> 
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id=""
                        label="Baixa do Atendimento"
                        multiline
                        fullWidth                
                        name='conclusion'
                        defaultValue={props?.values?.conclusion}
                        onBlur={handlerChangeValues}
                        placeholder='Informe a baixa do atendimento'
                        variant="outlined"   
                        error={errors?.conclusion && props?.values?.status > 3}
                        helperText={errors?.conclusion && props?.values?.status > 3 && "Informe a baixa do atendimento"} 
                        InputProps={{readOnly: disabled }}
                      />   
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <MobileDateTimePicker 
                          fullWidth
                          sx={{width: '100%'}}
                          label={'Data de Conclusão'} 
                          ampm={false} 
                          openTo="day" 
                          name="conclusion_date"
                          value={dayjs.tz(props.values?.conclusion_date)}
                          onChange={handlerChangeConcludeDate}
                          disabled={disabled}
                          format="DD/MM/YYYY HH:mm" 
                          />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: 'left', }}>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            name={'hide_panel'}
                            checked={props.values?.hide_panel === 'Y'} 
                            onChange={handleChangeChecks}
                            inputProps={{ 'aria-label': 'controlled' }} 
                            
                          />
                        }
                        label='Ocultar do Painel'
                      /> 
                    </Grid>                    
                    <Grid item xs={12} sx={{textAlign: 'center', }}>
                      <Typography sx={{fontSize: 12, color: (theme) => theme.palette.grey[500],}}>
                        Atendimento criado por {props?.values?.creator_user_name} em {props?.values?.created_date} às {props?.values?.created_hour}
                      </Typography>
                      <Typography sx={{fontSize: 12, color: (theme) => theme.palette.grey[500], display: (props?.values?.status === 5 && props?.values?.finish_user) ? '' : 'none'}}> 
                        Atendimento finalizado por {props?.values?.finish_user_name} em {props?.values?.final_date} 
                      </Typography>                      
                    </Grid>
                  </Grid>
                </Box> 
              </Box>
              <Box  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      m: 1, 
                      width: 150, 
                      height: '100%', 
                      }} 
              >
                <Tooltip title="Fechar" arrow>
                  <IconButton aria-label="Fechar" sx={{position: 'absolute', top: 7, right: 7, fontSize: 10, color: 'gray'}} onClick={handlerClose}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar Cliente" arrow>
                  <Button variant='outlined' color='primary' fullWidth 
                    sx={{...styleButtons, ...{display: (user?.requests_edit_client === 'Y' ? '' : 'none')}}} 
                    startIcon={<PersonSearchIcon />} 
                    onClick={selectClient}
                  >
                    Cliente
                  </Button>
                </Tooltip>
                <Tooltip title="Adicionar um novo serviço" arrow>
                  <Button variant='outlined' color='primary' fullWidth sx={styleButtons} startIcon={<PostAddIcon />} 
                    onClick={AddService}
                  >
                    Serviço
                  </Button>
                </Tooltip>
                <Tooltip title="Finalizar acompanhamento" arrow>
                  <Button variant='outlined' color='warning' fullWidth sx={{...styleButtons, ...{display: (booleanFinalize) ? '' : 'none'}}} 
                    startIcon={<BookmarkAddedIcon />} onClick={finalizeRequest} 
                  >
                    Finalizar
                  </Button> 
                </Tooltip>
                <Tooltip title="Salvar Alterações" arrow>
                  <Button variant='outlined' color='success' fullWidth sx={{...styleButtons}} //sx={{...styleButtons, ...{display: (props?.values?.status === 5) ? 'none' : ''}}} 
                  startIcon={<SaveIcon />} 
                    onClick={() => sendRequest(props.values)} 
                  >
                    Salvar
                  </Button> 
                </Tooltip> 
              </Box>
            </Box>
          </Box>
      </Box>
      </Modal>
    </div>
  );
}