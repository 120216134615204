import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  // toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  // didOpen: (toast) => {
  //   toast.addEventListener('mouseenter', Swal.stopTimer)
  //   toast.addEventListener('mouseleave', Swal.resumeTimer)
  // }
})


export const useShowMessages = () => {

    const ShowMessage = (type, title, msg, time = 3) => {
      if(type === 'warning'){
        return  Swal.fire({
                  icon: type,
                  title: title,
                  text: msg,
                  showConfirmButton: true,
                  confirmButtonColor: '#3085d6',
                  confirmButtonText: 'Confirmar',
                })
      } else {
        return  Toast.fire({
                  icon: type,
                  title: title,
                  text: msg,
                  timer: time*1000
                })
      }

    }

    const showPopup = (type, title) => {
      Swal.fire({
        position: "top-end",
        icon: type,
        title: title,
        showConfirmButton: false,
        timer: 1500
      });
    }

    const msgConfirm = (title, msg, btnConfirm, btnCancel, callback) => {
      Swal.fire({
        title: title,
        text: msg,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: btnConfirm,
        cancelButtonText: btnCancel
      }).then((result) => {
        if (result.isConfirmed) {
          callback();
        }
      })
      
    }

    const msgPassword = async (title, msg, btnConfirm, btnCancel, user, verifyPassword, callback) => {

      let realPassword = "";
    
      Swal.fire({
        title: title,
        text: msg,
        html: `
          <form id="passwordForm">
            <p >${msg}</p>
            <br>
            <input id="passwordInput" type="text" placeholder="Insira sua senha" style="width: 100%;" autocomplete="off" />
          </form>
          <style>
            #passwordInput {
              width: 100%;
              padding: 10px;
              border: 1px solid #d9d9d9;
              border-radius: 5px;
              font-size: 1rem;
              outline: none;
              box-shadow: none;
              transition: border-color 0.2s ease-in-out;
              letter-spacing: 2px;
            }

            #passwordInput:focus {
              border-color: #b4d5ff; /* Azul claro */
              box-shadow: 0 0 3px rgba(0, 123, 255, 0.25); /* Sutil efeito de sombra */
            }

            #passwordInput::placeholder {
              color: #bfbfbf; /* Cinza claro para o placeholder */
              font-style: italic;
            }
          </style>
        `,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: btnConfirm,
        cancelButtonText: btnCancel,
        didOpen: () => {
          const input = document.getElementById("passwordInput");
          input.addEventListener("input", (e) => {
            if (e.inputType === "deleteContentBackward") {
              const { selectionStart, selectionEnd } = input;
              if (selectionStart === 0 && selectionEnd === 0) {
                realPassword = "";
              } else {
                realPassword = realPassword.slice(0, -1);
              }
            } else {
              realPassword += e.data || ""; 
            }
            input.value = " * ".repeat(realPassword.length);
          });
        },
        preConfirm: async () => {
          if (!realPassword) {
            Swal.showValidationMessage("Insira sua senha para continuar");
          } else {
            await verifyPassword(user, realPassword).then((res) => {
                if (!res.Authenticated) {
                  Swal.showValidationMessage(res.msg);
                } else {
                  return realPassword; 
                }
              }         
            )
          }
          
        }
      }).then((result) => {
        if (result.isConfirmed) {
          showPopup('success', 'Liberação efetuada com sucesso');
          callback(); 
        } else {
          showPopup('error', 'Liberação cancelada');
        }
      });
    };
    
    
    

    return{ ShowMessage, msgConfirm, msgPassword }
}